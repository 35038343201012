import React from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { FormattedMessage } from 'react-intl';

const FeaturedProducts = () => (
  <StaticQuery
    query={graphql`
      query {
        allMarkdownRemark(
          limit: 8
          filter: {
            fileAbsolutePath: { glob: "**/content/products/**/*.fr.md" }
            frontmatter: { featured: { eq: "yes" } }
          }
          sort: { order: ASC, fields: [frontmatter___order] }
        ) {
          edges {
            node {
              fields {
                slug
              }
              id
              excerpt(pruneLength: 75)
              frontmatter {
                title
                path
                role
                available
                discount
                price
                cover {
                  childImageSharp {
                    fluid(quality: 90) {
                      ...GatsbyImageSharpFluid
                      src
                    }
                  }
                }
                avatar {
                  childImageSharp {
                    fluid(maxWidth: 1920, quality: 90) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <section className="featuredProducts" id="products">
        <div className="container mb-5">
          <div className="row">
            <h2 className="col-lg-10 offset-sm-1 mb-3">
              Boards
              <a href="/fr/products" className="btn btn-primary btn-lg float-right">
                Voir toutes les boards
              </a>
            </h2>
          </div>
          <div className="row">
            {data.allMarkdownRemark.edges.map((post) => (
              <div
                className="col-sm-6 col-md-6 col-lg-4 col-xl-4 mb-4"
                key={post.node.id}
              >
                <div className="product-card text-center">
                  <Link to={post.node.frontmatter.path}>
                    <Img
                      fluid={post.node.frontmatter.avatar.childImageSharp.fluid}
                      alt="Furlan Snowboards"
                    />
                  </Link>
                  <Link to={post.node.frontmatter.path}>
                    <h4 className="mb-1">{post.node.frontmatter.title}</h4>
                  </Link>
                  <div className="mb-2">
                    <Link to={post.node.frontmatter.path}>
                      <small>{post.node.frontmatter.role}</small>
                    </Link>
                  </div>
                  <Link to={post.node.frontmatter.path}>
                    <span className="badge badge-pill badge-warning badge-lg mb-2">
                      Acheter
                    </span>
                    <span className="badge badge-pill badge-primary badge-lg mb-2">
                      {!post.node.frontmatter.price == '0'
                        ? `${post.node.frontmatter.price}€`
                        : 'Club offer'}
                    </span>
                  </Link>
                  {post.node.frontmatter.discount == 'yes' && (
                    <span className="badge badge-pill badge-warning badge-lg">
                      -40%
                    </span>
                  )}
                  {post.node.frontmatter.available == 'no' && (
                    <span className="badge badge-pill badge-warning badge-lg">
                      Epuisée
                    </span>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    )}
  />
);

export default FeaturedProducts;
